.data-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.0459);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.data-modal-content {
  position: relative;
  width: 80%;
  height: max-content;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}

.data-modal-header {
  /* background-color: aqua; */
  position: relative;
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.close-button {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}

.close-button img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.data-modal-body {
  /* background-color: blue; */
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}

.data-modal-body span {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
