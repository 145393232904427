.card-image-holder {
  /* flex-wrap: wrap; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  /* background-color: aqua; */
  padding: 10px;
  gap: 20px;
}

.contactImageContainer {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f5f5f5; */
  /* padding: 20px; */
}
.contactImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 72vw;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* background-color: #fff; */
  /* margin-top: 20px; */
}

.form-map-holder {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  width: 92vw;
  /* background-color:yellow; */
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.formContainer {
  background-color: white;
  border-radius: 10px;
  border: 5px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 50vw;
  /* background-color: aqua; */
}

.formHeading {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 800;
  color: #000;
  text-align: center;
  margin-top: 20px;
  /* margin-bottom: px; */
}

.mapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40vw;
  height: 80vh;
}

@media screen and (max-width: 768px) {
  .card-image-holder {
    flex-direction: column;
    gap: 10px;
  }

  .cardContainer {
    flex-direction: column;
    gap: 10px;
    /* height: max-content; */
  }

  .formContainer {
    width: 85%;
  }

  .mapContainer {
    width: 300px;
    height: 350px;
  }
}
