.dashboard {
    padding: 2rem;
    background-color: #f5f5f5;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .dashboard-title {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .dashboard-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .dashboard-button:hover {
    background-color: #45a049;
  }
  
  .dashboard-button-destructive {
    background-color: #f44336;
  }
  
  .dashboard-button-destructive:hover {
    background-color: #e53935;
  }
  
  .dashboard-section {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .dashboard-section-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .dashboard-subsection-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .dashboard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .dashboard-item-content {
    flex-grow: 1;
  }
  
  .dashboard-item-heading {
    font-weight: 600;
  }
  
  .dashboard-item-link {
    color: #666;
  }
  
  .dashboard-item-delete {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #f44336;
  }
  
  .dashboard-form {
    margin-top: 1rem;
  }
  
  .dashboard-input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dashboard-delete-confirmation {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 4px;
  }
  
  .dashboard-delete-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }