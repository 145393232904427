@import url("https://fonts.googleapis.com/css2?family=Alegreya:wght@700&family=Inter:wght@400;600;800&display=swap");

body {
  margin: 0;
  padding: 0;
}

.fixedHeader {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .App{
  display: flex;
  flex-direction: column;
} */

.heroSection {
  position: relative;
  top: 30vh;
  padding: 10px;
  margin: 0 20px;
  height: max-content;
  /* width: 80%; */
  background-color: #d9d9d9;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}

.img-fluid {
  width: 600px;
}

.text-fluid {
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 768px) {
  .fixedHeader {
    width: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    height: 30vh;
    /* background-color: aqua; */
  }

  .img-fluid {
    width: 70vw;
  }
}

/* Login/Register Form Styles */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.login-form {
  display:flex;
  gap:5px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.login-form input {
  width: 300px;
  padding: 12px 16px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-form button {
  width: 300px;
  padding: 12px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 16px; /* Added margin-bottom */
}

.login-form button:hover {
  background-color: #45a049;
}

.login-form .cancel-button {
  background-color: #f44336;
  margin-top: 16px; /* Added margin-top */
}

.login-form .cancel-button:hover {
  background-color: #da190b;
}