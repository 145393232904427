.members-text-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 40px 40px;
}

.members-containerHeading {
  margin: 0;
  font-family: "Alegreya", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 0.14em;
}

.members-containerDescription {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #0f0e57;
  /* letter-spacing: 0.14em; */
}
