.a-box {
  display: inline-block;
  width: 240px;
  text-align: center;
}

.img-container {
  height: 230px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.inner-skew {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  /* transform: skew(0deg, 13deg); */
  font-size: 0px;
  /* margin: 30px 0px 0px 0px; */
  background: #c8c2c2;
  height: 250px;
  width: 200px;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 14px;
  /* height: 270px; */
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #04bcff;
  font-size: 18px;
}

.a-box-container {
  width: 100%;
  flex-direction: row;
  /* gap:50px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px;
}

.circle-image-container {
  /* margin-top: 10px; */
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* transform: skew(0deg, -13deg); */
}

.circle-image-container-inner {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* border: 2px solid black; */
  /* transform: skew(0deg, 13deg); */
}

.circle-image-container-inner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 72vw;
  height: 100%;
  /* background-color: #fff; */
  margin-top: 20px;
}

.card {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  /* background-color: #fff; */
  padding: 10px;
  text-align: center;
}

.card-content h3 {
  margin: 10px 0px;
  font-size: 18px;
  color: #04bcff;
}

.card-content p {
  word-wrap: normal;
  margin: 10px 0px;
  font-size: 14px;
  color: #000;
}

.card-content a {
  text-decoration: none;
  margin: 10px 0px;
  font-size: 14px;
  color: rgb(20, 39, 203);
}

.card-content a:hover {
  text-decoration: underline;
}
