.pageNavParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  height: max-content;
  border-radius: 15px;
}

.navHeadingParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  margin-top: 20px;
}

.navHeadingParent h1 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: black;
  /* border:2px solid #411010; */
}

.navLinksParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: chartreuse; */
}

.navLinksList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: blueviolet; */
  align-items: center;
  margin: 0;
  padding: 0;
}

.pageNavButton {
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: #C7A2A2; */
  border-radius: 15px;
  margin: 1rem 0;
  padding: 1rem 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
  width: 230px;
  /* width: 90%; */
  height: max-content;
}

.pageNavLink {
  text-decoration: none;
  /* color: #411010; */
}

.pageNavButton:hover {
  filter: invert(100%);
}

.active {
  filter: invert(100%);
}
