@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;800&display=swap");

.headingparent {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  /* width: 100%; */
  height: 15vh;
  background-color: #d9d9d9;
  /* position: fixed;
    top: 0;
    left: 0;
    right: 0; */
  margin: 20px 20px 5px 20px;
}

.collegeDetails {
  width: 80vw;
  margin-right: 20px;
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5a4b4b;
}

.logoContainer {
  /* margin-left: 40px; */
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.logo {
  /* object-fit: contain; */
  width: 100%;
  height: 100%;
}

.collegeName {
  font-family: "Inter", sans-serif;
  font-size: 2vw;
  color: #fec5c5;
  margin: 0;
  padding: 5px;
}

.line {
  width: 95%;
  border: 1px solid #0f0e57;
  /* margin-top: 20px; */
}

.collegeAddress {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  color: #c8a3a3;
  margin-top: 2px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .collegeDetails {
    /* background-color: #0f0e57; */
    width: 70%;
    margin-right: 10px;
  }

  .collegeName {
    text-align: center;
    font-size: 12px;
  }

  .collegeAddress {
    text-align: center;
    font-size: 8px;
  }

  .logoContainer {
    width: 60px;
    height: 60px;
  }
}
