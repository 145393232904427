.notice-parent {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  align-items: flex-start;
  /* width: 100%; */
  /* height: 100%; */
}

.notice-container {
  margin: 10px;
  height: 100vh;
  width: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 25px;
  background-color: #c7a2a2;
}

.notice-container h1 {
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
}

.notice-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: max-content;
  width: 80%;
  margin: 10px;
  margin: 20px 0px;
  gap: 10px;
}

.notice-item {
  font-family: "Inter", sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
}

.notice-item a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.notice-item a:hover {
  text-decoration: underline;
  color: #0f0e57;
}

@media screen and (max-width: 768px) {
  .notice-parent {
    flex-direction: column;
  }

  .notice-container {
    height: max-content;
    width: 80vw;
  }

  .notice-list {
    margin-left: 10px;
  }

  .notice-item {
    font-size: 20px;
  }
}
